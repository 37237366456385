import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGoatsList() {
  // Use toast
  const toast = useToast()

  const refChildrenListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'ternak', sortable: true },
    { key: 'age', sortable: true },
    { key: 'genetic_status', sortable: true },
    { key: 'gender', sortable: true },
    { key: 'weight', sortable: true },
    { key: 'pen', sortable: true },
    { key: 'last_updated', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalGoats = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refChildrenListTable.value ? refChildrenListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGoats.value,
    }
  })

  const refetchData = () => {
    refChildrenListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery,], () => {
    refetchData()
  })

  const fetchChildren = (ctx, callback) => {
    store
      .dispatch('app-give-birth/fetchChildren', {
        id: router.currentRoute.params.id
      })
      .then(response => {
        const data = response.data.data
        const { total } = response.data.data
        
        callback(data)
        totalGoats.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching goats list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // UI
  const resolveGenderAnimalVariant = gender => {
    if (gender === 'Male') return 'info'
    if (gender === 'Female') return 'danger'
    return 'primary'
  }

  return {
    fetchChildren,
    tableColumns,
    perPage,
    currentPage,
    totalGoats,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refChildrenListTable,

    refetchData,

    //ui
    resolveGenderAnimalVariant,

  }
}
