import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPregnant(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/give_births/', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChildren(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/give_births/${id}/children`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addChild(ctx, goatData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/animals', goatData, {
            headers: {
              'Content-type': 'multipart/form-data'
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
  },
}
