<template>
  <b-card
    no-body
    class="card-apply-job"
  >
    <b-card-body class="p-2 pb-0 mb-0">

      <b-row align-v="center" class="align-items=center">
        <b-col cols="4" class="pr-0 mr-0"> 
          <b-avatar 
          size="78px"
          :src="`http://127.0.0.1:8000/storage/AnimalMonitoring/1750666235233381.jpg`"
        />
        </b-col>

       <b-col cols="8" class="pr-0 mr-0 pl-0">
        <b-row class="mb-0 pb-0">
          <b-col cols="12">
            <h4 class="apply-job-title text-left mb-1 font-weight-normal">
              {{ goatHero.animal_name }}
            </h4>
          </b-col>
    

          <b-col cols="12">
            <b-card-text class="text-left mb-1">
              <b-badge
                pill
                variant="light-primary"
                class="font-weight-normal"
              >
              {{ goatHero.age }}
              </b-badge>
            </b-card-text>  
          </b-col>

          <b-col cols="12">
            <b-row class="mt-0 m-0">
              <b-col cols="3" class="p-0">
                <b-avatar size="48px" rounded variant="light-primary">
                  <feather-icon size="32" icon="BriefcaseIcon" class="light-primary" />
                </b-avatar>
              </b-col>
              <b-col cols="9">
                <b-row>
                  <h5 class="mb-0 font-weight-normal">{{ goatHero.animal_type }}</h5>                     
                </b-row>
                <b-row>
                  <p class="font-weight-light">Status</p>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        
        
       </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BCardText, BCardBody, BButton,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    goatHero: {
      required: true,
    },
  },
}
</script>
