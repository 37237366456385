<template>
  <div>
    <goat-child-add-new
      :is-add-new-goat-sidebar-active.sync="isAddNewGoatSidebarActive"
      @refetch-data="refetchData"
    />

    <b-row class="match-height">
      <b-col xl="4" lg="4" md="12" sm="12" class="mr-0">
          <goat-hero :goatHero="goatHero"/>
      </b-col>  
      <!-- <b-col  xl="8" md="8" sm="12" class="mr-0">
          <goat-profile :goatProfile="goatProfile" />
      </b-col>   -->
    </b-row>
    
    <!-- Table Container Card -->

    <b-card
      no-body
      class="mb-0"
    >

        <!-- Table Top -->
        <b-row class="pt-2 pl-2 pr-2">  
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4>Anakan</h4>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
            <b-button
                variant="primary"
                @click="isAddNewGoatSidebarActive = true"
              >
                <span class="text-nowrap">+ Add Record</span>
              </b-button>
          </b-col>
        </b-row>
        <hr/>
        <b-row class="pl-2 pr-2 pb-2">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            
          >
            <div class="d-flex align-items-center justify-content-end">
            </div>
          </b-col>
        </b-row>
      <b-table
        ref="refChildrenListTable"
        class="position-relative"
        :items="fetchChildren"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(ternak)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.photo"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveGenderAnimalVariant(data.item.gender)}`"
                :to="{ name: 'goatsDetail', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'goatsDetail', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <!-- <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template> -->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalGoats"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>


  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import axios from '@axios'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useAnimalGiveBirthDetail from './useAnimalGiveBirthDetail'
import giveBirthStoreModule from './giveBirthStoreModule'
import GoatHero from '@/layouts/components/GoatHero.vue'
import GoatChildAddNew from './GoatChildAddNew.vue'

export default {
  components: {
    GoatHero,
    GoatChildAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const APP_GIVEBIRTH_STORE_MODULE_NAME = 'app-give-birth'

    // Register module
    if (!store.hasModule(APP_GIVEBIRTH_STORE_MODULE_NAME)) store.registerModule(APP_GIVEBIRTH_STORE_MODULE_NAME, giveBirthStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_GIVEBIRTH_STORE_MODULE_NAME)) store.unregisterModule(APP_GIVEBIRTH_STORE_MODULE_NAME)
    })

    const isAddNewGoatSidebarActive = ref(false)

    const {
      fetchChildren,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChildrenListTable,

      refetchData,

      // Extra Filters
      resolveGenderAnimalVariant,
    } = useAnimalGiveBirthDetail()

    const blankGoatData = {
      animal_name: 'label_kambing',
      age: 'label_age',
      animal_type: 'label_status',
    }

    const blankMatingData = {
      mating_id: null,
      animal_female_id: null,
      animal_male_id: null,
      mating_date: 'label_date',
      mating_type: 'label_type',
      animal_male: 'label_male',
      source_male: 'label_source',
      number_of_babies: 'label_count_babies',
      colostrum_volume: 'label_colostrum',
      end_of_postpartum: 'label_pospartum',
      birth_realization: 'label_birth_date',
      place: 'label_place',
      pen_name: 'label_pen_name',
    }

    const goatHero = ref(blankGoatData)
    const matingDetail = ref(blankMatingData)

    onMounted(()=> {
      axios.get(`give_births/${router.currentRoute.params.id}`)
      .then((response) => {
        const data = response.data.data
        blankGoatData.animal_name = data.animal_mating.animal_female_name
        blankGoatData.age = data.animal_mating.animal_female_age ?? 'N/A'
        blankGoatData.animal_type = data.animal_mating.animal_female_status
        blankMatingData.mating_date = data.animal_mating.mating_date
        blankMatingData.mating_type = data.animal_mating.mating_type
        blankMatingData.animal_male = data.animal_mating.animal_male
        blankMatingData.source_male = data.animal_mating.source_male
        blankMatingData.mating_id = data.animal_mating.mating_id
        blankMatingData.animal_female_id = data.animal_mating.animal_female_id
        blankMatingData.animal_male_id = data.animal_mating.animal_male_id
        blankMatingData.number_of_babies = data.number_of_babies
        blankMatingData.colostrum_volume = data.colostrum_volume
        blankMatingData.end_of_postpartum = data.end_of_postpartum

        goatHero.value = blankGoatData
        matingDetail.value = blankMatingData

      })
    })

    return {
      // Sidebar
      isAddNewGoatSidebarActive,

      // hero
      goatHero,
      matingDetail,

      avatarText,
      fetchChildren,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChildrenListTable,

      refetchData,

      //ui
      resolveGenderAnimalVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
